* {
  box-sizing: border-box;
  user-select: none;
}

html,
body,
#root {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading {
  position: fixed;
  opacity: 0.5;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  transition: 0.3s ease;
  transition-property: color, background-color;
}

.app-container.theme-0 {
  background-color: #111314;
  color: #f8f9fa;
}

.app-container.theme-1 {
  background-color: #f8f9fa;
  color: #111314;
}

.app-container.theme-2 {
  background-color: #ffff00;
  color: #333333;
}

.app-container.theme-2 .small {
  color: #72723c;
}

.icon {
  width: 100%;
}

.word-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

h1,
.icon {
  margin: 0;
  padding: 0;
  font-size: 3rem;
  height: 4.125rem;
}

.small-words-container {
  display: flex;
}

.small {
  font-size: 2rem;
  font-weight: 300;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  width: 100%;
  color: #868e96;
  height: auto;
  margin: 0 0.3em;
}

.word-counter,
.words-left {
  font-weight: 300;
  opacity: 0.4;
  bottom: 5rem;
  position: fixed;
}

.settings {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 2rem 0.6rem;
  width: 100%;
}

.settings li {
  flex: 1 1 auto;
}

.theme-container {
  position: relative;
}

.reload-btn,
.theme-btn,
.info-btn {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 300ms ease;
}

@media only screen and (min-width: 48rem) {
  h1,
  .icon {
    font-size: 8rem;
    height: 10.9375rem;
  }

  .small {
    font-size: 3rem;
  }

  .settings {
    padding: 2rem;
  }

  .word-counter,
  .words-left {
    font-weight: 500;
  }

  .reload-btn:hover,
  .theme-btn:hover,
  .info-btn:hover {
    opacity: 0.4;
  }
}
